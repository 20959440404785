import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-account-overview',
  templateUrl: './account-overview.component.html',
  styleUrls: ['./account-overview.component.css']
})
export class AccountOverviewComponent implements OnInit {

  constructor(private http: HttpClient, private route: ActivatedRoute) { }

  displayedColumns: string[] = ['matchStarted', 'victory', 'heroName', 'kills', 'deaths', 'assists'];

  list: any[] = [];
details: any = {};

  ngOnInit(): void {

  this.route.paramMap.subscribe(p => {
    this.http.get<any[]>(`https://dota-api.cfx.dk/match/matches-by-user/${p.get('id')}`).subscribe({next: (r) => {
      this.list = r;
    }});
    this.http.get<any>(`https://dota-api.cfx.dk/accounts/${p.get('id')}/details`).subscribe({next: r => {
      this.details = r;
  }});
  
  });
  

   
  }
}
