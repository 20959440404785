import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  templateUrl: './match-details.component.html',
  styleUrls: ['./match-details.component.css']
})
export class MatchDetailsComponent implements OnInit {

  constructor(private http: HttpClient, private route: ActivatedRoute) { }

  match: any;

  displayedColumns: string[] = ['accountId','playerSlot', 'heroName', 'kills', 'deaths', 'assists'];


  ngOnInit(): void {
    this.route.paramMap.subscribe(p => {
    this.http.get<any[]>(`https://dota-api.cfx.dk/match/${p.get('id')}`).subscribe({next: (r) => {
      this.match = r;
    }});
  });
  }

}
