import { Component, OnDestroy } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnDestroy  {
  ngOnDestroy(): void {
    this.watcher.unsubscribe();
  }

  watcher: Subscription;

  showMenu: boolean = true;

  toggleMenu() {
    this.showMenu = !this.showMenu;
  }

  constructor(mediaObserver: MediaObserver) {

    this.watcher = mediaObserver.asObservable().subscribe({
      next: () => {
        this.isExpanded = !mediaObserver.isActive("sm") && !mediaObserver.isActive("xs");

        this.showMenu = this.isExpanded;
      }
    });
  }

  isExpanded = true;
}
