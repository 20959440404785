<div fxFlex fxLayout="row" fxLayoutAlign.gt-sm="center center">
    <div fxFlex.gt-sm="70" fxFlex.lt-sm="100" fxFlex.gt-lg="50">
        <mat-card>
            <mat-card-header>
              <mat-card-title>Search</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                    <mat-form-field appearance="outline" fxFlex="100">
                        <mat-label>Enter 32 bit steam id (i.e. 22167485)</mat-label>
                        <input matInput placeholder="32bit steamid" [(ngModel)]="searchQuery" (keyup.enter)="search()"/>
                    </mat-form-field>
            </mat-card-content>

            <div fxLayoutAlign="end center">
                <button mat-raised-button color="primary" (click)="search()">SEARCH</button>
            </div>
  
          </mat-card>
    </div>
</div>