<div fxFlex fxLayout="row">
    <mat-toolbar color="primary">
        <mat-icon (click)="toggleMenu()">menu</mat-icon> Dota Club
     </mat-toolbar>
    
    <mat-sidenav-container class="container">
        <mat-sidenav mode="side" [opened]="showMenu" fxFlex [ngStyle]="{ 'width.em': isExpanded ? '17' : '4' }">
            <sidemenu></sidemenu>
        </mat-sidenav>
        <mat-sidenav-content fxFlex>
            <div style="padding: 20px;">
                <router-outlet></router-outlet>
            </div>
        </mat-sidenav-content>
      </mat-sidenav-container>
</div>