<div fxLayout="column" fxFlex fxLayoutGap="20px">

    <mat-card fxFlex>
      <mat-card-header>
        <mat-card-title>
          Scoreboard
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div fxLayout="column" fxLayoutGap="20px">
          <mat-spinner *ngIf="match?.games?.length == 0"></mat-spinner>

          <table mat-table [dataSource]="match?.games" class="mat-elevation-z8" fxFlex *ngIf="match?.games?.length != 0" matSort>
  
              <ng-container matColumnDef="accountId">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Player </th>
                <td mat-cell *matCellDef="let element"> {{element.accountId}} </td>
              </ng-container>

              <ng-container matColumnDef="playerSlot">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Team </th>
                <td mat-cell *matCellDef="let element"> {{element.playerSlot > 100 ? "Dire" : "Radiant"}} </td>
              </ng-container>
  
              <ng-container matColumnDef="heroName">
                <th mat-header-cell *matHeaderCellDef> Hero </th>
                <td mat-cell *matCellDef="let element"> <i class="d2mh {{element.hero.name}}"></i> </td>
              </ng-container>
    
            <ng-container matColumnDef="kills">
              <th mat-header-cell *matHeaderCellDef ngStyle.lt-md="width: 25px;"> K<span fxHide.lt-md>ills</span> </th>
              <td mat-cell *matCellDef="let element"> {{element.kills}} </td>
            </ng-container>
          
            <ng-container matColumnDef="deaths">
              <th mat-header-cell *matHeaderCellDef ngStyle.lt-md="width: 25px;"> D<span fxHide.lt-md>eaths</span> </th>
              <td mat-cell *matCellDef="let element"> {{element.deaths}} </td>
            </ng-container>
          
            <ng-container matColumnDef="assists">
              <th mat-header-cell *matHeaderCellDef ngStyle.lt-md="width: 25px;"> A<span fxHide.lt-md>ssists</span> </th>
              <td mat-cell *matCellDef="let element"> {{element.assists}} </td>
            </ng-container>
          
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          
        </div>

      </mat-card-content>
    </mat-card>

    <mat-card fxFlex="70">
      <mat-card-header>
        <mat-card-title>
          Match details
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div fxLayout="column" fxLayoutGap="20px">

          <div fxLayout="row" fxFlex fxLayoutGap="20px">
            <mat-form-field appearance="outline" fxFlex="50">
              <mat-label>Started</mat-label>
              <input matInput readonly [ngModel]="match?.started">
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex="50">
              <mat-label>Ended</mat-label>
              <input matInput readonly [ngModel]="match?.ended">
            </mat-form-field>
          </div>
          <div fxLayout="row" fxFlex fxLayoutGap="20px">
            <mat-form-field appearance="outline" fxFlex="50">
              <mat-label>Game mode</mat-label>
              <input matInput readonly [ngModel]="match?.gameMode">
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex="50">
              <mat-label>Radiant win</mat-label>
              <input matInput readonly [ngModel]="match?.radiantWin">
            </mat-form-field>
          </div>

       
        </div>
      </mat-card-content>
    </mat-card> 
</div>