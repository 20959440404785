import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: './job-metrics.component.html',
  styleUrls: ['./job-metrics.component.css']
})
export class JobMetricsComponent implements OnInit {

  constructor(private http: HttpClient) { }

  multi: any[] = [];
  isLoading = true;

  // options
  showLabels: boolean = true;
  animations: boolean = true;
  xAxis: boolean = true;
  yAxis: boolean = true;
  showYAxisLabel: boolean = true;
  showXAxisLabel: boolean = true;
  xAxisLabel: string = 'Hour';
  yAxisLabel: string = 'Humidity / Temperature';

  ngOnInit(): void {
    this.http.get<any[]>(`https://dota-api.cfx.dk/jobs/metrics?metricsSize=250`).subscribe(result => {


    
      this.multi = result;// [{name: "ClientId", series: result.map(e => ({name: e.clientId, value: e.total}))}];
      
      this.isLoading = false;
    });
  }

}
