import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccountOverviewComponent } from './components/account/overview/account-overview.component';
import { JobMetricsComponent } from './components/job-metrics/job-metrics.component';
import { MatchDetailsComponent } from './components/match-details/match-details.component';
import { SearchComponent } from './components/search/search.component';

const routes: Routes = [
  {path: 'account/:id/overview', component: AccountOverviewComponent},
  {path: 'job-metrics', component: JobMetricsComponent},
  {path: 'matches/:id', component: MatchDetailsComponent},
  {path: 'search', component: SearchComponent},
  {path: '**', redirectTo: 'search'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
