<div fxLayout="column" fxFlex fxLayoutGap="20px">

    <mat-card fxFlex.lt-lg="250px">
      <mat-card-header>
        <mat-card-title>
          Account Details
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div fxLayout="column" fxLayoutGap="20px">
          <mat-form-field appearance="outline" fxFlex.lt-md>
            <mat-label>Account Name</mat-label>
            <input matInput readonly placeholder="TBD" value="TBD">
          </mat-form-field>
          <div fxLayout="row" fxFlex fxLayoutGap="20px">
            <mat-form-field appearance="outline" fxFlex="50">
              <mat-label>Top victorious month</mat-label>
              <input matInput readonly placeholder="TBD" [ngModel]="details?.topVictoriousMonth">
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex="50">
              <mat-label>Most frequent hero</mat-label>
              <input matInput readonly [ngModel]="details?.mostFrequentHero?.name">
            </mat-form-field>
          </div>
          
        </div>

      </mat-card-content>
    </mat-card>

    <mat-card fxFlex="70">
      <mat-card-header>
        <mat-card-title>
          Recent matches
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-spinner *ngIf="list.length == 0"></mat-spinner>

        <table mat-table [dataSource]="list" class="mat-elevation-z8" fxFlex *ngIf="list.length != 0" matSort>

          <ng-container matColumnDef="matchStarted">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Started </th>
              <td mat-cell *matCellDef="let element"> <i matTooltip="{{element.matchStarted | date:'long'}}">{{element.matchStarted | date:'medium'}}</i> </td>
            </ng-container>

            <ng-container matColumnDef="victory">
              <th mat-header-cell *matHeaderCellDef> Victory </th>
              <td mat-cell *matCellDef="let element"> {{element.victory}} </td>
            </ng-container>

            <ng-container matColumnDef="heroName">
              <th mat-header-cell *matHeaderCellDef> Hero </th>
              <td mat-cell *matCellDef="let element"> <i class="d2mh {{element.heroName}}"></i> </td>
            </ng-container>
  
          <ng-container matColumnDef="kills">
            <th mat-header-cell *matHeaderCellDef ngStyle.lt-md="width: 25px;"> K<span fxHide.lt-md>ills</span> </th>
            <td mat-cell *matCellDef="let element"> {{element.kills}} </td>
          </ng-container>
        
          <ng-container matColumnDef="deaths">
            <th mat-header-cell *matHeaderCellDef ngStyle.lt-md="width: 25px;"> D<span fxHide.lt-md>eaths</span> </th>
            <td mat-cell *matCellDef="let element"> {{element.deaths}} </td>
          </ng-container>
        
          <ng-container matColumnDef="assists">
            <th mat-header-cell *matHeaderCellDef ngStyle.lt-md="width: 25px;"> A<span fxHide.lt-md>ssists</span> </th>
            <td mat-cell *matCellDef="let element"> {{element.assists}} </td>
          </ng-container>
        
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" [routerLink]="['/matches', row.matchId]"></tr>
        </table>

      </mat-card-content>
    </mat-card> 
</div>